import React from 'react';
import logo from '@package/components/layout/images/Logo.svg';

const LogoFooter = () => (
  <figure className="icon">
    <img src={logo} width="165" height="70" alt="Provincia di Pisa Logo" />
  </figure>
);

export default LogoFooter;
