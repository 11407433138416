import React from 'react';
import logo from '@package/components/layout/images/Logo.svg';

const Logo = () => (
  <figure className="icon">
    <img src={logo} width="255" height="108" alt="Provincia di Pisa Logo" />
  </figure>
);

export default Logo;
